<template>
  <div>{{ categorie }}</div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    props: {object: {type: Object, required: true}},
    data(){
        return {

        }
    },
    computed: {
        ...mapGetters({
            categories: 'sanitaire/categorieAnalyses',
            typeRecherches: 'sanitaire/typeRecherches' 
        }),
        categorie(){
            let tr = this.typeRecherches.find(item => item.uid === this.object.typeRecherche)
            if(!tr) return '-'
            let c = this.categories.find(item => item.uid === tr.categorieAnalyse)
            if(!c) return '-'
            return c.libelle
        }
    }
}
</script>

<style>

</style>